import Container from '../../layout/Container'
import Image from '../../Image'
import { cn } from '../../../lib/cn'

export default function IconCards( { sectionTitle, cards } ) {
  return (
    <Container>
      <div className="pb-16">
        { sectionTitle && (
          <h2 className="text-red font-general-sans-semi-bold text-medium mb-8">
            { sectionTitle }
          </h2>
        ) }
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          { cards.map( card => (
            <Card
              key={ card.id }
              card={ card }
            />
          ) )}
        </div>
      </div>
    </Container>
  )
}

function Card( { card } ) {
  const image = card.cardImage?.[ 0 ]

  const Component = ({children, className}) => {
    if ( card.cardLink?.url ) {
      return (
        <a 
          className={ className }
          href={ card.cardLink?.url } 
          target={ card.cardLink?.target }
        >
          { children }
        </a>
      )
    }

    return <div className={ className }>{ children }</div>
  }

  return (
    <Component className="group block relative">
      { image && (
        <div style={{maxWidth: `${image.width}px`}}>
          <Image
            src={ image.url }
            alt={ image.imageAltText }
            width={ image.width }
            height={ image.height }
            sizes="( max-width: 749 ) 87vw, calc( 29vw - 4rem )"
          />
        </div>
      ) }
      <div>
        <h3 className="font-general-sans-semi-bold mb-4">{ card.cardTitle }</h3>
        <div 
          className="font-light"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={ { __html: card.cardBody } } 
        />
        { card.cardLink?.link && (
          <span className="font-general-sans-semi-bold underline underline-offset-3 decoration-red text-small mt-4 inline-block min-h-0">
            Read more
          </span>
        ) }
      </div>
    </Component>
  )
}
